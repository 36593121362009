import PropTypes from "prop-types";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { isMobileOnly } from "react-device-detect";
import EmbedController from "../../../../embed/EmbedController";

export const HeadersBuildSection = ({ headerLabel, headerLineImgSrc, iconSrc = null, productsState }) => {
  const [show, setShow] = useState(false);
  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);

  const handleSeeOptionsClick = () => {
    const hostname = EmbedController?.parentUrl?.slice(0, 30).includes("calipercovers.com") ? "https://calipercovers.com" : "https://mgpdev.wpengine.com";

    const optionUrl = `${hostname}${productsState.activeObj.option_link}`;

    if (EmbedController.isEmbedded) EmbedController.sendParentUrlChange(optionUrl);
    else window.location.assign(optionUrl);
  };

  const productDetailsModal = () => (
    <Modal show={show} size="md" style={{ zIndex: 1202 }} onHide={handleCloseModal} keyboard={false}>
      <Modal.Header>
        <Modal.Title className="mx-auto text-uppercase">Product Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul>
          <li>{`Model: ${productsState.activeObj.year} ${productsState.activeObj.make} ${productsState.activeObj.model}`}</li>
          <li>{`Set of ${productsState.activeObj.set_count} Custom Caliper Covers`}</li>
          <li>{`Part Number: ${productsState.activeObj.part_number}CUSDF`}</li>
          {productsState.activeObj.pass_through.option && (
            <li>
              <span className="brand-color">{`Option: ${productsState.activeObj.pass_through.option} `}</span>
              <span
                className="fw-bold hover-animation"
                role="button"
                onClick={handleSeeOptionsClick}
                onTouchStart={(e) => {
                  e.preventDefault();
                  handleSeeOptionsClick();
                }}
              >
                (more information)
              </span>
            </li>
          )}
        </ul>
      </Modal.Body>
    </Modal>
  );

  const handleSeeProductDetailsClick = () => {
    handleShowModal();
    if (EmbedController.isEmbedded) EmbedController.sendGTMAnalyticsEvent({ event: "Product Details Click - MGP" });
  };

  return (
    <>
      <div className="headers d-flex flex-column mt-3">
        <div className="hover-animation d-flex mb-0 mx-auto" role="button" onClick={handleSeeProductDetailsClick}>
          <img className="me-1" style={{ height: "19px", marginTop: "2px" }} alt="header icon" src={iconSrc} />
          <h5 className="mb-0 text-center text-uppercase">{headerLabel} </h5>
        </div>
        {!isMobileOnly && <img style={{ width: "100px" }} className="mt-1 mx-auto" alt="header underline" src={headerLineImgSrc} />}
      </div>
      <div className="Modal">{productDetailsModal()}</div>
    </>
  );
};

HeadersBuildSection.propTypes = {
  headerLabel: PropTypes.string.isRequired,
  headerLineImgSrc: PropTypes.string.isRequired,
  subheaderLabel: PropTypes.string,
  iconSrc: PropTypes.string,
};
